.Title {
  font-family: "Playwrite FR Moderne", cursive;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  background: linear-gradient(45deg, var(--light--blue--color),var(--medium--blue--color), var(--dark--blue--color));
}

.Location {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-top: 1.5rem;
}

.SpecialDisplay {
  display: flex;
}

.Company {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 1rem 0;
}

.Date {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: auto;
  margin-top: 1.2rem;
}

.Description {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
}

.SkillSets {
  text-decoration: wavy underline var(--light--blue--color);
  text-align: center;
  margin-bottom: 1rem;
}

.Skill, .Achievement {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}

.Divider {
  margin: 0 0.5rem;
  font-size: 1.6rem;
  color: var(--light--blue--color);
}

@media screen and (max-width: 767px){
  .Date {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 640px){
  .Title {
    font-size: 2rem;
  }

  .Location {
    font-size: 1.6rem;
  }

  .Company {
    font-size: 1.4rem;
  }

  .Description {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 500px){
  .Title {
    font-size: 1.6rem;
  }

  .Location {
    font-size: 1.2rem;
  }

  .SpecialDisplay {
    display: block;
    text-align: center;
  }

  .Description {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 400px){
  .ExperienceContainer {
    padding: 2rem;
  }

  .Description {
    font-size: 1rem;
  }
}