.Title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(45deg, var(--light--blue--color),var(--medium--blue--color), var(--dark--blue--color));
}

.ProgressBar {
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  border-radius: 1.5rem;
  background-color: var(--offwhite--color--opacity--80);
}

.SkillName {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.SkillList {
  font-size: 1.8rem;
}

@media screen and (max-width: 991px) {
  .ProgressBar {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 992px) {
  .SkillList {
    font-size: 2rem;
  }
}