:root {
  font-size: 10px;
  --white--color: #ffffff;
  --offwhite--color: #d9d9d9;
  --offwhite--color--opacity--80: rgba(217, 217, 217, 0.80);
  --offwhite--color--opacity--70: rgba(217, 217, 217, 0.70);
  --dark--aquamarine--color: #00442d;
  --medium--aquamarine--color: #009361;
  --aquamarine--color: #00ba7b;
  --light--aquamarine--color: #00e195;
  --dark--viridian--color: #002313;
  --medium--viridian--color: #03503e;
  --viridian--color: #40826d;
  --light--viridian--color: #53a98e;
  --dark--blue--color: #0c1b2a;
  --medium--blue--color: #1c588c;
  --light--blue--color: #4e9fbf;
  --yellow--color: #f2b749;
  --light--yellow--color: #FCE279;
  --orange--color: #f27830;
  --burgundy--color: #800020;
  --dark--burgundy--color: #73001d;
  --light--burgundy--color: #A52A2A;
  --black--color: #000000;
  --black--color--opacity--80: rgba(0, 0, 0, 0.80);
  --black--color--opacity--70: rgba(0, 0, 0, 0.70);
}

body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  color: var(--white--color);
  background-color: var(--dark--blue--color);
  background: url('assets/images/developer-8764523_1280.jpg') no-repeat center center fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
