.NavBar {
  font-family: "Playwrite FR Moderne", cursive;
  font-size: 2rem;
  width: 5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  background-color: var(--black--color--opacity--80) !important;
  cursor: pointer;
  position: fixed;
  z-index: 2;
  overflow-y: auto;
  scrollbar-width: thin;
}

.Name {
  color: var(--white--color) !important;
  text-shadow: 0.2rem 0.2rem 0.2rem var(--viridian--color);
  font-family: "Playwrite FR Moderne", cursive;
  margin: 1rem 0;
  font-size: 2.4rem;
}

.NavBarExpanded {
  width: 20rem;
  background: linear-gradient(45deg, var(--light--blue--color),var(--medium--blue--color), var(--dark--blue--color));
  box-shadow: inset 0 0 1rem var(--medium--blue--color);
}

.NavLinksContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow-y: auto;
}

.NavBarExpanded .NavLinksContainer {
  text-align: left;
}

.NavLinks {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.NavLink {
  color: var(--white--color) !important;
  text-decoration: none;
  color: inherit;
  align-items: center;
  padding: 1rem 0;
  display: block;
  transition: all 0.5s ease-in-out;
}
.NavLink:hover {
  text-decoration: none;
}

.NavPersonalInfo {
  font-size: 1.2rem;
  text-align: center;
}

.NavPersonalInfo p {
  cursor: default;
}

.NavSocialLinks {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  margin: 1rem 0;
  margin-left: -0.5rem;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-1.5rem);
  }
  60% {
      transform: translateY(-.75rem);
  }
}

.NavLink:hover i {
  animation: bounce 1s infinite;
}

.Active i {
  color: var(--light--viridian--color) !important;
}

.NavBar::-webkit-scrollbar {
  width: 0.25rem;
}

.NavBar::-webkit-scrollbar-thumb {
  background-color: var(--viridian--color);
  border-radius: 1rem;
}

@media screen and (max-height: 750px){
  .NavSocialLinks {
    position: relative;
    margin-top: 15rem;
  }
  .NavBarExpanded .NavSocialLinks {
    margin-top: 5rem;
  }
}