.App {
  font-family: "Playfair Display", serif;
  min-height: 100vh;
}

.PaddingTop {
  padding-top: 5rem;
}

.MainContainer {
  display: block;
  position: relative;
  text-align: justify;
  align-content: center;
  margin-bottom: 5rem;
}

.MainHeader {
  background-color: var(--black--color--opacity--80);
  font-family: 'Playwrite FR Moderne', cursive;
  text-align: center;
  width: fit-content;
  padding: 2rem;
  margin: auto;
  margin-top: 5rem;
  z-index: 1;
  text-decoration: underline;
  box-shadow: inset 0 0 1rem var(--medium--blue--color);
}

.ContentContainer {
  background-color: var(--black--color--opacity--80) !important;
  text-align: justify;
  margin-top: 5rem;
  padding: 5rem;
  z-index: 1;
  box-shadow: inset 0 0 1rem var(--medium--blue--color);
}

a {
  color: var(--white--color) !important;
  text-decoration: none;
  color: inherit;
  align-items: center;
}

a i {
  font-size: 2.6rem;
  text-shadow: 0.2rem 0.2rem 0.2rem var(--viridian--color) !important;
}

a:hover, a:hover i {
  color: var(--aquamarine--color) !important;
  transition: color 0.5s ease-in-out;
}

@media screen and (max-width: 640px){
  .MainContainer {
    margin-left: 3.5rem;
  }
}

@media screen and (max-width: 575px){
  .PaddingTop {
    padding-top: 0;
  }
}