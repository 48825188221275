.ImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2.5rem auto;
  overflow: hidden;
  max-width: 1280px;
  max-height: 720px;
}

.Image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .Image {
    width: 960px;
    height: 540px;
  }
}

@media screen and (max-width: 991px) {
  .Image {
    width: 640px;
    height: 360px;
  }
}

@media screen and (max-width: 767px) {
  .Image {
    width: 480px;
    height: 270px;
  }
}

@media screen and (max-width: 499px) {
  .Image {
    width: 320px;
    height: 180px;
  }
}