.SummaryParagraph {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
}

.List {
  list-style-type: none;
}

@media screen and (min-width: 1200px){
  .SummaryParagraph {
    font-size: 2.2rem;
  }
  .List {
    font-size: 2rem !important;
  }
}

@media screen and (min-width: 992px){
  .List {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 640px){
  .SummaryParagraph {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px){
  .SummaryParagraph {
    font-size: 1.2rem;
  }
  .SummaryContainer {
    padding: 2rem;
  }
}